import { Flex } from "@chakra-ui/react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Features from "../components/Features"

const featuresData = [
  {
    title: 'Personalized VOIP Number',
    description: 'Generate a personalized VOIP number to be generated by the intercom system.',
  },
  {
    title: 'Feature 2',
    description: 'Description of feature 2.',
  },
  {
    title: 'Feature 3',
    description: 'Description of feature 3.',
  },
];


export default function Landing(props) {
  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ xl: "1200px" }}
      m="0 auto"
      {...props}
      bg="#140d37" 
      
    >
      <Header />
      <Hero
        title="Bring Your Buzzer Into The 21st Century"
        subtitle="Automate Your Buzzer with Buzzcut"
        image="https://i.imgur.com/WwU5FPX.png"
        ctaText="Create your account now"
        ctaLink="/signup"
      />
      {/* <Features features={featuresData}/> */}
      <Footer />
    </Flex>
  )
}
